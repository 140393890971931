@import url("https://fonts.googleapis.com/css2?family=Syne+Mono&display=swap");

body {
  margin: 0;
  font-family: "Syne Mono", "Courier New", Courier, monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-track {
  background-color: #1a1f22;
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:window-inactive {
  background: #ffdaaf;
  border-radius: 9px;
  opacity: 0.5;
}
